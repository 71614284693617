import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import { cookieBanner } from './cookie';

require('jquery-ui');
require('jquery-ui-touch-punch');

import 'slick-carousel';
import AutoNumeric from 'autonumeric';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

if (process.env.ENABLE_SENTRY_INTEGRATION === 'true') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [],
  });
}

function formatNumber(selector) {
	var options = {
		currencySymbol: '€ ',
		decimalCharacter: ',',
		digitGroupSeparator: '.',
		watchExternalChanges: true,
	};
	new AutoNumeric(selector, options);
}

function formatNumberNoDecimal(selector) {
  var options = {
    currencySymbol: '€ ',
		decimalCharacter: ',',
		digitGroupSeparator: '.',
		watchExternalChanges: true,
    decimalPlaces: 0,
  };

  new AutoNumeric(selector, options);
}

var chkPricing = $('.main__pricing');
if ( chkPricing.length ) {
  formatNumber('#amount-total');
  formatNumber('#amount-statement');
  formatNumber('#amount-employee');
  formatNumberNoDecimal('#select-revenue-handle');
  formatNumber('#input-revenue');
};

function featuresTabs() {
  if ( window.matchMedia( '(min-width: 1px) and (max-width: 767px)' ).matches ) {
    $('#features-tabs').tabs({
      heightStyle: 'content',
      hide: { effect: 'fade', duration: 200 },
      show: { effect: 'fade', duration: 200 },
    });
  } else {
    $('#features-tabs').tabs({
      heightStyle: 'auto',
      hide: { effect: 'fade', duration: 200 },
      show: { effect: 'fade', duration: 200 },
    });
  }
};

function revenue() {
  var revenueHandle = $('#select-revenue-handle');
  var revenueSelect = $('#select-revenue');
  var revenueInput = $('#input-revenue');
  var limits = {
    min: 1000,
    max: 1000000,
  };

  revenueInput.attr('min', limits.min);
  revenueInput.attr('max', limits.max);

  function updatePrice(value) {
    if (value <= 29000) {
      AutoNumeric.set('#amount-statement', 315);
    } else if (value <= 150000) {
      AutoNumeric.set('#amount-statement', Math.round(value * 0.011));
    } else {
      AutoNumeric.set('#amount-statement', Math.round(150000 * 0.011 + (value - 150000) * 0.0055));
    }
  }

  if ( revenueSelect.length ) {
    revenueSelect.slider({
      create: function () {
        revenueHandle.text($(this).slider('value'));
        AutoNumeric.set('#select-revenue-handle', $(this).slider('value'));
      },
      value: 1000,
      min: limits.min,
      max: limits.max,
      step: 1000,
      animate: 200,
      slide: function (event, ui) {
        var val = ui.value;

        AutoNumeric.set('#select-revenue-handle', val);
        AutoNumeric.set('#input-revenue', val);
      },
      change: function (event, ui) {
        var value = $(this).slider('value');
        updatePrice(value);
      },
    });
    revenueInput.val(revenueSelect.slider('value'));
    updatePrice(revenueSelect.slider('value'));

    revenueInput.change(function () {
      var value = Math.max(limits.min, Math.min(limits.max, AutoNumeric.getNumber(this)));
      AutoNumeric.set('#input-revenue', value);
      AutoNumeric.set('#select-revenue-handle', value);
      revenueSelect.slider('option', 'value', value);
    });
  } else {
    return;
  }
}

function receipt() {
  var handle = $('#select-receipts-handle');
  var select = $('#select-receipts');
  var input = $('#input-receipts');
  var limits = {
    min: 1,
    max: 200,
  };

  input.attr('min', limits.min);
  input.attr('max', limits.max);

  function updatePrice(value) {
    var basePrice = 5.5;

    if (value <= 50) {
      AutoNumeric.set('#amount-total', value * 4.3 + basePrice);
    } else if (value <= 100) {
      AutoNumeric.set('#amount-total', 50 * 4.3 + (value - 50) * 3.6 + basePrice);
    } else {
      AutoNumeric.set('#amount-total', 50 * 4.3 + 50 * 3.6 + (value - 100) * 3.2 + basePrice);
    }
  }

  if ( select.length ) {
    select.slider({
      create: function () {
        handle.text($(this).slider('value'));
      },
      value: 1,
      min: limits.min,
      max: limits.max,
      step: 1,
      animate: 200,
      slide: function (event, ui) {
        handle.text(ui.value);
        input.val(ui.value);
      },
      change: function (event, ui) {
        var value = $(this).slider('value');
        updatePrice(select.slider('value'));
      },
    });
    input.val(select.slider('value'));
    updatePrice(select.slider('value'));

    input.change(function () {
      var value = Math.max(limits.min, Math.min(limits.max, $(this).val()));
      input.val(value);
      select.slider('option', 'value', value);
      handle.text(value);
    });
  } else {
    return;
  };
}

function employee() {
  var handle = $('#select-employee-handle');
  var select = $('#select-employee');
  var input = $('#input-employee');
  var limits = {
    min: 1,
    max: 50,
  };

  input.attr('min', limits.min);
  input.attr('max', limits.max);

  function updatePrice(value) {
    if (value <= 3) {
      var employeePrice = 25.9;
      AutoNumeric.set('#amount-employee', value * employeePrice);
    } else if (value <= 6) {
      var employeePrice = 24.9;
      AutoNumeric.set('#amount-employee', value * employeePrice);
    } else if (value <= 10) {
      var employeePrice = 23.9;
      AutoNumeric.set('#amount-employee', value * employeePrice);
    } else if (value <= 15) {
      var employeePrice = 22.9;
      AutoNumeric.set('#amount-employee', value * employeePrice);
    } else if (value <= 20) {
      var employeePrice = 21.9;
      AutoNumeric.set('#amount-employee', value * employeePrice);
    } else {
      var employeePrice = 20.9;
      AutoNumeric.set('#amount-employee', value * employeePrice);
    }
  }

  if ( select.length ) {
    select.slider({
      create: function () {
        handle.text($(this).slider('value'));
      },
      value: 1,
      min: limits.min,
      max: limits.max,
      step: 1,
      animate: 200,
      slide: function (event, ui) {
        handle.text(ui.value);
        input.val(ui.value);
      },
      change: function (event, ui) {
        var value = $(this).slider('value');
        updatePrice(value);
      },
    });
    input.val(select.slider('value'));
    updatePrice(select.slider('value'));

    input.change(function () {
      var value = Math.max(limits.min, Math.min(limits.max, $(this).val()));
      input.val(value);
      select.slider('option', 'value', $(this).val());
      handle.text($(this).val());
    });
  } else {
    return;
  }
}

function faqToggle() {
	var toggle = $('.faq__entry');
	var plus = $('.entry__trigger');
  var chkFaq = $('#section-question');

  if ( chkFaq.length ) {
    toggle.click(function () {
      $(this).find(plus).toggleClass('is-active');
      $(this).find('.entry__desc').fadeToggle();
    });
  } else {
    return;
  }
}

function initAboutSlider() {
	var slider = $('#testimonial-slider');
	slider.not('.slick-initialized').slick({
		dots: true,
		arrows: false,
		centerMode: false,
		rows: 0,
		adaptiveHeight: false,
		variableWidth: false,
		slidesToShow: 2,
		infinite: false,
		responsive: [
			{
				breakpoint: 1800,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});
}

function showNav() {
  var didScroll;
  var lastScrollTop = 0;
  var delta = 35;
  var intro = $('#section-start').outerHeight() - 200;

  var navNormal = $('.main-nav.normal');
  var navSub = $('.main-nav.sub');
  var navSubHeight = navSub.outerHeight();


  $(window).scroll(function() {
    didScroll = true;
    if ( navNormal.length ) {
      hasScrolled();
    } else {
      hasScrolledSub();
    }
  })

  function hasScrolled() {
    var st = $(window).scrollTop();

    if ( Math.abs( lastScrollTop - st ) <= delta )
        return;

    if ( st > lastScrollTop ) {
      // Scroll Down
      $('#main-nav').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if ( st + $(window).height() < $(document).height() && st > intro ) {
        $('#main-nav').removeClass('nav-up').addClass('nav-down');
      } else {
        $('#main-nav').removeClass('nav-down').addClass('nav-up');
      }
    }

    lastScrollTop = st;
  }

  function hasScrolledSub() {
    var st = $(window).scrollTop();

    if ( Math.abs( lastScrollTop - st ) <= delta )
        return;

    if ( st > lastScrollTop ) {
      // Scroll Down
      $('#main-nav').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if ( st + $(window).height() < $(document).height() ) {
        $('#main-nav').removeClass('nav-up').addClass('nav-down');
      } else {
        $('#main-nav').removeClass('nav-down').addClass('nav-up');
      }
    }

    lastScrollTop = st;
  }
}

function mobileNav() {
	var trigger1 = $('#main-burger');
	var trigger2 = $('#intro-burger');
	var navLink = $('.smooth-scroll');

	trigger1.click(function () {
		$('body').toggleClass('show-nav');
	});

	trigger2.click(function () {
		$('body').toggleClass('show-nav');
	});

	navLink.click(function () {
		$('body').removeClass('show-nav');
	});
}



$('.smooth-scroll').on('click', function (e) {
	e.preventDefault();
	var target = $(this).attr('href');
	$('html, body').animate(
		{
			scrollTop: $(target).offset().top - 150,
		},
		1000
	);
});

// GSAP

function vpFades() {
  var chkFade = $('.a-fade');
  var chkSlide = $('.a-slide');
  var chkSlide2 = $('.a-slide2');

  if ( chkFade.length ) {
    gsap.utils.toArray('.a-fade').forEach(function (elem) {
      const anim = gsap.fromTo(
        elem,
        { autoAlpha: 0, y: 50 },
        { duration: 1.5, autoAlpha: 1, y: 0 }
      );
      ScrollTrigger.create({
        trigger: elem,
        animation: anim,
        toggleActions: 'play none none none',
        once: true,
      });
    });
  } else {
    return;
  }

  if ( chkSlide.length ) {
    gsap.set('.a-slide', { x: -30, opacity: 0 });
    ScrollTrigger.batch('.a-slide', {
      batchMax: 3, // maximum batch size (targets)
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          x: 0,
          stagger: { each: 0.5, grid: [1, 2] },
          overwrite: true,
        }),
    });
  } else {
    return;
  }

  if ( chkSlide2.length ) {
    gsap.set('.a-slide2', { x: -100, opacity: 0 });
    ScrollTrigger.batch('.a-slide2', {
      batchMax: 3, // maximum batch size (targets)
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          x: 0,
          stagger: { each: 0.5, grid: [1, 3] },
          overwrite: true,
        }),
    });
  } else {
    return;
  }
}

function wordSwitch() {
  var chkHeadline = $('#headline');

  if ( chkHeadline.length ) {
    const words = ['Steuerberater.', 'Buchhalter.', 'Lohnverrechner.'];
    let cursor = gsap.to('#cursor', {
      opacity: 0,
      duration: 0.75,
      ease: 'power2.inOut',
      repeat: -1,
    });
    let masterTL = gsap.timeline({ repeat: -1 });

    words.forEach((word) => {
      let tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 1 });
      tl.to('#headline', { duration: 2, text: word, ease: 'power2.out' });
      masterTL.add(tl);
    });
  } else {
    return;
  }
}

$(document).ready(function () {
  cookieBanner();
  faqToggle();
  showNav();
  mobileNav();
  initAboutSlider();
});

wordSwitch();
featuresTabs();
revenue();
receipt();
employee();
vpFades();

$(window).resize( function() {
  featuresTabs();
});
