import $ from 'jquery';

function setCookieConsent() {
  const expiresAt = new Date();
  const oneYearInMilliseconds = 365 * 24 * 3600 * 1000;
  expiresAt.setTime(expiresAt.getTime() + oneYearInMilliseconds);
  const i = "expires=" + expiresAt.toUTCString();
  document.cookie = "cookieconsent=true;" + i + ";path=/";
}

function isCookieBannerRequired() {
  return document.cookie.indexOf("cookieconsent=true") === -1;
}

export function cookieBanner() {
  const $banner = $('#cookie');

  if (isCookieBannerRequired()) {
    $('#cookie-consent').on('click', function(event) {
      setCookieConsent();
      $banner.slideUp('fast');

      $(this).off('click');
    });

  } else {
    $banner.hide();
  }
}
